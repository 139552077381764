<template>
  <div class="notice" v-if="webConfig.notice">
    <div class="words">{{ webConfig.notice }}</div>
  </div>
  <div class="absolute white-color bg-red-color z-10 right-0 bottom-0 px-10px py-2px" v-if="!prod">
    <i class="fa fa-warning yellow-color"></i> 当前系统api服务为开发模式，如是生产环境请注意配置：spring.profiles.active=prod
  </div>
  <Layout class="app-frame" :siderCollapsed="sliderCollapsed" :siderFixed="layoutConfig.siderFixed">
    <Sider :theme="layoutConfig.siderTheme">
      <appMenu :theme="layoutConfig.siderTheme"></appMenu>
    </Sider>
    <Layout :headerFixed="layoutConfig.headerFixed">
      <HHeader theme="white">
        <appHead :layoutConfig="layoutConfig"></appHead>
      </HHeader>
      <Content class="flex flex-column">
        <app-tabs/>
        <div class="app-frame-content flex1 p-14px" style="height: 0;overflow-y: auto">
          <template v-for="tab in tabs">
            <Suspense>
              <component v-show="currentTab === tab.key" is="ReportView" :dimension="tab.params.dimension"
                         :reportId="tab.params.reportId" v-if="tab.key.startsWith('ReportView')"/>
              <component v-show="currentTab === tab.key" :is="getKey(tab.key)" v-bind="tab.props" v-else/>
              <!-- 加载中状态 -->
              <template #fallback>
                <div class="bg-white-color h-full flex justify-center items-center flex-column">
                  <div class="mb-16px">
                    <div class="loading">
                      <div class="shape shape-1"></div>
                      <div class="shape shape-2"></div>
                      <div class="shape shape-3"></div>
                      <div class="shape shape-4"></div>
                    </div>
                  </div>
                  <div>页面加载中,请稍后...</div>
                </div>
              </template>
            </Suspense>
          </template>
        </div>
      </Content>
    </Layout>
  </Layout>
</template>
<script>

import appHead from './app/app-header';
import appMenu from './app/app-menu';
import appFooter from './app/app-footer';
import {mapGetters, mapMutations, mapState} from 'vuex';
import AppTabs from "@/views/app/app-tabs.vue";
import {message} from "heyui.ext";

export default {
  name: "FXY",
  data() {
    return {
      selectedTab: "Home",
      isRouterAlive: true,
      layoutConfig: {
        siderTheme: 'dark',
        showSystab: false,
        headerFixed: true,
        siderFixed: true
      }
    };
  },
  watch: {
    currentTab(val) {
      this.selectedTab = val;
      if (!this.tabMap[val].ignoreCache) {
        localStorage.setItem(this.currentTabKey, JSON.stringify(this.tabMap[val]));
      }
      location.hash = val;
    }
  },
  methods: {
    ...mapMutations(['changeTab', 'newTab']),
    closeTab(key) {
      this.$store.commit('closeTab', key);
    },
    tabClick({key}) {
      if (this.currentTab !== key) {
        this.$store.commit('changeTab', key);
      }
    },
    getKey(key) {
      return key.split(":")[0]
    }
  },
  computed: {
    ...mapState(['sliderCollapsed', 'tabs', 'currentTab', 'menus', 'webConfig', 'prod']),
    ...mapGetters(['tabMap', 'currentTabKey'])
  },
  components: {
    AppTabs,
    appHead,
    appMenu,
    appFooter
  },
  created() {
    const currentTab = localStorage.getItem(this.currentTabKey)
    if (currentTab) {
      this.newTab(JSON.parse(currentTab));
    }
  },
  errorCaptured(args) {
    if (args.name === 'ChunkLoadError') {
      alert('系统已更新，即将刷新页面...');
      window.location.reload();
      return false;
    } else {
      message.error(args.message);
      return true;
    }
  }
};
</script>
<style scoped lang="less">
.h-icon-close-min {
  margin-left: 10px;
  font-weight: bold;

  &:hover {
    color: #f60 !important;
  }
}

.notice {
  width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  position: fixed;
  left: 0;
  z-index: 1000;
  font-size: 12px;
  font-weight: bold;
}

.notice .words {
  position: absolute;
  display: inline-block;
  color: #ff6600;
  white-space: nowrap;
  animation: marquee 60s linear infinite;
}

.notice:hover .words {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}
</style>
