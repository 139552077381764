<template>
  <div class="login-container">
    <div class="login-background">
      <div class="logo">
        <div class="logo-header">
          <img class="logo-image" v-if="webConfig.loginLogo" :src="'/api'+webConfig.loginLogo">
          <img class="logo-image" v-else src="../assets/login/login-fxy.png">
        </div>
        <p class="slogan">{{ webConfig.loginSubSlogan || '一键取票，让记账更轻松，更智能！' }}</p>
        <img class="login-left-img" v-if="webConfig.loginLeftCh" :src="'/api'+webConfig.loginLeftCh">
        <img class="login-left-img" v-else src="../assets/login/login-img.png">
      </div>
    </div>

    <div class="login-box">
      <div class="login-right-top" v-if="GIT==1">
        <DropdownCustom class="mx-16px" placement="left-start" trigger="hover" :toggleIcon="false">
          <template #content>
            <div class="qr-code-container">
              <img v-if="webConfig.qrCode" :src="'/api'+webConfig.qrCode">
              <img v-else src="../assets/code.png">
            </div>
          </template>
        </DropdownCustom>
      </div>

      <h2 class="login-title">{{ getTitle }}</h2>
      
      <transition name="scale" mode="out-in">
        <component :is="viewName" @changeView="changeView"/>
      </transition>

      <!-- <div class="login-copyright">
        {{ webConfig.copyright || 'Powered by ' + APP_COPM }} {{ year }}
        <a v-if="webConfig.icp" href="https://beian.miit.gov.cn" target="_blank">{{ webConfig.icp }}</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import LoginForm from "@/views/login/LoginForm.vue";
import ForgotPassword from "@/views/login/ForgotPassword.vue";
import Registered from "@/views/login/Registered.vue";

export default {
  name: "Login",
  components: {LoginForm, ForgotPassword, Registered},
  data() {
    return {
      year: new Date().getFullYear(),
      viewName: 'LoginForm'
    }
  },
  computed: {
    ...mapState(['webConfig']),
    getTitle() {
      switch(this.viewName) {
        case 'LoginForm': return '账号登录'
        case 'Registered': return '注册账号'
        case 'ForgotPassword': return '找回密码'
        default: return '账号登录'
      }
    }
  },
  methods: {
    changeView(toView) {
      this.viewName = toView;
    }
  }
}
</script>

<style scoped lang="less">
.login-container {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #f5f7fa;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    background: #fff;
  }
}

.login-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/login/login-bg.png") no-repeat center center;
  background-size: cover;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.logo {
  height: auto;
  width: 40%;
  min-width: 300px;
  position: fixed;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 1366px) {
    width: 35%;
  }

  @media screen and (max-width: 768px) {
    position: static;
    width: 100%;
    padding: 40px 20px;
    transform: none;
    text-align: center;
  }

  .logo-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .logo-image {
    max-height: 80px;
    width: auto;
    object-fit: contain;

    @media screen and (max-width: 1366px) {
      max-height: 60px;
    }
  }

  .slogan {
    color: #999;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }

  .login-left-img {
    margin-top: 90px;
    margin-left: 0;
    width: 100%;
    height: auto;
    object-fit: contain;
    max-width: 500px;

    @media screen and (max-width: 1366px) {
      max-width: 400px;
    }
  }
}

.login-box {
  position: fixed;
  width: 480px;
  height: auto;
  min-height: 620px;
  padding: 80px 50px 52px;
  box-sizing: border-box;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: 0 4px 20px 0 rgb(0 54 128 / 8%);

  @media screen and (max-width: 1366px) {
    width: 400px;
    padding: 60px 40px 40px;
  }

  @media screen and (max-width: 768px) {
    position: static;
    width: 100%;
    min-height: auto;
    padding: 20px;
    transform: none;
    box-shadow: none;
  }
}

.login-title {
  font-size: 20px;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  display: inline-block;
  padding-bottom: 13px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 30%;
    width: 40%;
    height: 4px;
    background-color: #08f;
  }
}

.qr-code-container {
  width: 250px;
  height: 250px;
  border-radius: 4px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.login-copyright {
  text-align: center;
  color: rgba(192, 196, 204, 1);
  padding: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  a {
    color: inherit;
    text-decoration: none;
  }
}

// 过渡动画
.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
