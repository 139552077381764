<template>
  <div class="login-frame">
    <div class="login-form">
      <div class="phone-field">
        <input 
          type="text" 
          v-model="form.mobile" 
          placeholder="请输入手机号"
          class="input-field"
          :class="{ 'error': mobileError }"
          @input="validateMobile"
          maxlength="11"
        >
        <span class="input-suffix">
          <i v-if="form.mobile" class="icon clear-icon" @click="clearMobile">
            <svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor">
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
            </svg>
          </i>
        </span>
        <div class="error-message" v-if="mobileError">{{ mobileError }}</div>
      </div>

      <div class="verify-field">
        <input 
          type="text" 
          v-model="form.code" 
          placeholder="请输入验证码"
          class="input-field verify-input"
          :class="{ 'error': codeError }"
          maxlength="4"
        >
        <SmsVerificationCode :mobile="form.mobile" :disabled="!isValidMobile"/>
        <div class="error-message" v-if="codeError">{{ codeError }}</div>
      </div>
      
      <div class="password-field">
        <input 
          :type="showPassword ? 'text' : 'password'" 
          v-model="form.newPassword" 
          placeholder="请输入新密码"
          class="input-field"
          :class="{ 'error': passwordError }"
        >
        <span class="input-suffix">
          <i v-if="form.newPassword" class="icon clear-icon" @click="form.newPassword = ''">
            <svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor">
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
            </svg>
          </i>
          <i class="icon eye-icon" @click="showPassword = !showPassword">
            <svg v-if="!showPassword" viewBox="64 64 896 896" focusable="false" data-icon="eye-invisible" width="1em" height="1em" fill="currentColor">
              <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 0 0 0-51.5zm-63.57-320.64L836 122.88a8 8 0 0 0-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 0 0 0 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 0 0 0 11.31L155.17 889a8 8 0 0 0 11.31 0l712.15-712.12a8 8 0 0 0 0-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 0 0-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 0 1 146.2-106.69L401.31 546.2A112 112 0 0 1 396 512z"></path>
              <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 0 0 227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 0 1-112 112z"></path>
            </svg>
            <svg v-else viewBox="64 64 896 896" focusable="false" data-icon="eye" width="1em" height="1em" fill="currentColor">
              <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
            </svg>
          </i>
        </span>
        <div class="error-message" v-if="passwordError">{{ passwordError }}</div>
      </div>

      <div class="password-field">
        <input 
          :type="showPassword ? 'text' : 'password'" 
          v-model="form.repeatPassword" 
          placeholder="请再次输入新密码"
          class="input-field"
          :class="{ 'error': confirmError }"
        >
        <span class="input-suffix">
          <i v-if="form.repeatPassword" class="icon clear-icon" @click="form.repeatPassword = ''">
            <svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor">
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
            </svg>
          </i>
        </span>
        <div class="error-message" v-if="confirmError">{{ confirmError }}</div>
      </div>

      <button class="submit-button" @click="handleSubmit" :disabled="loading">
        {{ loading ? '提交中...' : '修改密码' }}
      </button>

      <div class="bottom-links">
        <a href="javascript:void(0)" @click="$emit('changeView','LoginForm')">返回登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@api/common";
import { message } from "heyui.ext";
import SmsVerificationCode from "../../components/SmsVerificationCode"

export default {
  name: 'ForgotPassword',
  components: {
    SmsVerificationCode
  },
  data() {
    return {
      form: {
        mobile: "",
        code: "",
        newPassword: "",
        repeatPassword: "",
      },
      loading: false,
      showPassword: false,
      countdown: 0,
      mobileError: '',
      codeError: '',
      passwordError: '',
      confirmError: ''
    }
  },
  computed: {
    isValidMobile() {
      const phoneReg = /^1[3-9]\d{9}$/;
      return phoneReg.test(this.form.mobile);
    }
  },
  methods: {
    clearMobile() {
      this.form.mobile = '';
      this.mobileError = '';
    },
    
    validateMobile() {
      const phoneReg = /^1[3-9]\d{9}$/;
      if (!this.form.mobile) {
        this.mobileError = '请输入手机号码';
      } else if (!phoneReg.test(this.form.mobile)) {
        this.mobileError = '请输入正确的手机号码';
      } else {
        this.mobileError = '';
      }
    },

    // 发送验证码
    async sendCode() {
      if (!this.isValidMobile) return;
      
      try {
        await common.sendMsg(this.form.mobile);
        this.countdown = 60;
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {
        message.error(error?.response?.data?.msg || '发送验证码失败');
      }
    },

    // 提交修改
    async handleSubmit() {
      // 清除错误提示
      this.mobileError = '';
      this.codeError = '';
      this.passwordError = '';
      this.confirmError = '';

      // 表单验证
      const phoneReg = /^1[3-9]\d{9}$/;
      if (!this.form.mobile) {
        this.mobileError = '请输入手机号码';
        return;
      }
      if (!phoneReg.test(this.form.mobile)) {
        this.mobileError = '请输入正确的手机号码';
        return;
      }
      if (!this.form.code) {
        this.codeError = '请输入验证码';
        return;
      }
      if (!this.form.newPassword) {
        this.passwordError = '请输入新密码';
        return;
      }
      if (!this.form.repeatPassword) {
        this.confirmError = '请再次输入新密码';
        return;
      }
      if (this.form.newPassword !== this.form.repeatPassword) {
        this.confirmError = '两次输入的密码不一致';
        return;
      }

      this.loading = true;
      try {
        await common.resetPassword(this.form);
        message.success('密码重置成功');
        this.$emit('changeView', 'LoginForm');
      } catch (error) {
        message.error(error?.response?.data?.msg || '重置密码失败');
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped lang="less">
.login-frame {
  width: 100%;
  
  .login-frame-title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
  }
}

.login-form {
  width: 100%;
}

.input-field {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: #F7F9FC;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  transition: all 0.3s;

  &:focus {
    background: #EDF2FC;
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }

  &.error {
    border: 1px solid #ff4d4f;
    background-color: #fff2f0;
    
    &:focus {
      border-color: #ff4d4f;
      background-color: #fff2f0;
      box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
  }
}

.phone-field,
.password-field,
.verify-field {
  position: relative;
  margin-bottom: 30px;
}

.verify-field {
  display: flex;
  gap: 12px;
  position: relative;
  margin-bottom: 30px;
  
  .input-field {
    flex: 1;
  }
}

.input-suffix {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  
  .icon {
    padding: 0 4px;
    cursor: pointer;
    font-size: 14px;
    
    &.clear-icon {
      color: rgba(0, 0, 0, 0.25);
    }
    
    &.eye-icon {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.submit-button {
  width: 100%;
  height: 48px;
  background: #1890ff;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 24px;

  &:hover {
    background: #40a9ff;
  }

  &:disabled {
    background: #b5b5b5;
    cursor: not-allowed;
  }
}

.bottom-links {
  text-align: center;
  margin-top: 24px;

  a {
    color: #1890ff;
    font-size: 14px;
    text-decoration: none;
  }
}

.error-message {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 1;
}
</style>
